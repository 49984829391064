<template>
    <main class="h-screen flex items-center justify-center">
        <div
            class="w-80 border border-gray-100 rounded-md shadow-2xl px-8 py-12 flex flex-col justify-center"
        >
            <img
                src="@/assets/images/payment-successful.svg"
                alt="payment successful"
                class="w-3/4 mx-auto"
            />
            <h1 class="font-bold text-2xl text-center mt-3">Payment Successful</h1>
            <p class="text-center mt-3 mb-6">
                Your payment was successful!<br />
                Thank you for choosing Legend.
            </p>
            <a
                href="https://track.legend.ng"
                class="w-48 mx-auto mt-5 text-center rounded-md py-2 px-3 bg-primary-color-orange text-white"
            >
                Track your installation
            </a>
        </div>
    </main>
</template>

<script>
export default {}
</script>

<style></style>
